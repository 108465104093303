import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import generateAgrement from 'app/entreprises/detail-entreprise/generate-agrement';
import { EntrepriseService } from 'app/entreprises/entreprise.service';
import { GlobalConstants } from 'app/global-constants';

@Component({
  selector: 'app-info-agrement',
  templateUrl: './info-agrement.component.html',
  styleUrls: ['./info-agrement.component.css']
})
export class InfoAgrementComponent implements OnInit {
  entreprise: any;
  demande: any;
  dossiers: any;
  view_pdf:any = false;
  url_file: any;
  @ViewChild('closeModalAgreement') closeModalAgreement;
  @ViewChild('closeModalTeleversement') closeModalTeleversement;
  messageErreurAgreement: string = "";
  messageErreurTeleversement: string = "";
  
  file: File = null;
  urlAgrementSigne: any;
  viewAgrementSigne: boolean = false
  savingAgrement: boolean = false;
  savingTeleversement: boolean = false;
  tabAgentSelected = false;
  tabAgentLabel = "Agents";
  tabRedevanceSelected = false;
  tabRedevanceLabel = "Redevances";

  constructor(private router: Router, private entrepriseService: EntrepriseService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    GlobalConstants.hasPermissionRedirection('details_entreprise',this.router)
    this.getDetails()
  }

  getDetails() {
    let params = this.route.snapshot.params;
    
    if(Object.keys(params).includes('id')) {
      let id = params.id;
      this.entrepriseService.getDemandeById(id).subscribe(
        (response)=>{
          this.demande = response.demande
          this.entreprise = response.demande.entreprise
          this.dossiers = response.dossiers
        }, (error) => {
          console.log(`${error}`);
          
        }
      )
      
      

    } else {
      this.entrepriseService.getEntreprises().subscribe(
      (response) => {
        this.entreprise = response.data
      },
      (error) => GlobalConstants.errorRequest(error)
    )
    }
  }

  cancel() {
    this.router.navigate(['/agrements/list']);
  }

  genererPdf(){
    if(this.viewAgrementSigne) {
      this.viewAgrementSigne = false;
    }

    let pdf = generateAgrement(this.entreprise)
    pdf.getDataUrl((dataUrl) => {
      this.url_file = dataUrl;
      this.view_pdf = true;
    });
  }

  telecharger(){
    let pdf = generateAgrement(this.entreprise)
    pdf.download('Agrement'+ '.pdf')
  }

  fermerAgrement(){
    this.view_pdf = false;
  }

  onSubmit(form: NgForm) {
    if(this.savingAgrement) {
      return;
    }

    this.savingAgrement = true;
    let numero = form.controls['numero'].value
    
    this.entrepriseService.agreerEntreprise(this.entreprise?.id, numero).subscribe(
      (reponse) => {
        this.savingAgrement = false;

        if(reponse.statut) {
          this.entreprise.numero_agreement = numero
          GlobalConstants.showNotification(reponse.message, "success")
          this.closeModalAgreement.nativeElement.click()
        } else {
          this.messageErreurAgreement = reponse.message
        }
        
      },

      (error) => {
        this.savingAgrement = false;
        GlobalConstants.errorRequest(error)
      }
    )
  }

  televersement(event) {
    if(this.savingTeleversement) {
      return;
    }
    
    this.savingTeleversement = true;
  
    this.entrepriseService.televerserAgreement(this.entreprise?.id, this.file).subscribe(
      (reponse: any) => {
        this.savingTeleversement = false;

        if(reponse.statut) {
          this.entreprise.agreement_signe = reponse.entreprise.agreement_signe
          GlobalConstants.showNotification(reponse.message, "success")
          this.closeModalTeleversement.nativeElement.click()
        } else {
          this.messageErreurTeleversement = reponse.message
        }
      }
    );
  }

  toggleAffichageAgrementSigne() {
    if(this.view_pdf) {
      this.view_pdf = false;
    }

    this.urlAgrementSigne = `${GlobalConstants.FILE_BASE_URL}${this.entreprise.agreement_signe}`;
    this.viewAgrementSigne = !this.viewAgrementSigne;
  }

  fermerAgrementSigne() {
    this.viewAgrementSigne = false;
  }

  telechagerAgrementSigne(event) {
    location.assign(`${GlobalConstants.FILE_BASE_URL}${this.entreprise.agreement_signe}`)
  }

  onFileChange($event){
    this.file = $event.target.files[0];
    
  }

  isAdmin() {
    return GlobalConstants.is_admin();
  }

  isAgence(){
    return GlobalConstants.is_agence();
  }

  selectedTabChange(changeEvent: MatTabChangeEvent) {
    if(changeEvent.tab.textLabel == this.tabAgentLabel) {
      this.tabAgentSelected = true;
    }
    
    if(changeEvent.tab.textLabel == this.tabRedevanceLabel) {
      this.tabRedevanceSelected = true;
    }
  } 
 
}
