import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalConstants } from 'app/global-constants';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Subscription } from 'rxjs/Subscription';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { NotificationService } from './notification.service';

var misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
}

@Component({
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html',
  styleUrls: ['./navbar.navbar.css']
})

export class NavbarComponent implements OnInit, AfterViewInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private _router: Subscription;
  public open: boolean = false;

  @ViewChild("navbar-cmp", { static: false }) button;
  notifications: any;
  typeUsers: any;
  users: any;
  user: any;
  typeUser: any;
  action_tracks: any;
  is_consultant: boolean;
  is_societe: boolean;
  notifications_unread: any;
  notification_unread: any;
  notif_unread: any;
  notifs: any;
  notifications_unreads: any;

  list_notification_unread: any;

  list_notification_read: any;
  unread: any;
  actif: any;
  badge_notif = ""
  is_admin: boolean;
  messageSuccess: boolean;
  competences: any
  timeout: any;
  interval: any;
  current_user: any;

  unread_notification_count: number = 0

  constructor(location: Location, private renderer: Renderer2, private element: ElementRef, private router: Router,
    private serviceNotification: NotificationService) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {


    this.listTitles = ROUTES.filter(listTitle => listTitle);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
    this.is_consultant = GlobalConstants.is_consultant()
    this.is_societe = GlobalConstants.is_societe()
    this.is_admin = GlobalConstants.is_admin()
    this.is_societe = GlobalConstants.is_societe()

    this.getNotification()
  }

  handlerNotification(object_id, notif_id, type, receive_to_id) {
    this.badge_notif = ""
    this.user = GlobalConstants.current_user()
    this.serviceNotification.updteReadNotification(notif_id).subscribe(
      (response) => {
        this.list_notification_unread = response
        this.unread_notification_count = response.length

        this.reloadComponent()

        if (type == 'societe') {
          if (this.is_societe) {
            this.router.navigate(['/societe/details'])
          } else {
            this.router.navigate(['/societe/show', receive_to_id])
          }
        } else if(type == "offre") {
          this.router.navigate(['/offre/show', object_id])
        }  else if(type == "souscription") {
          this.router.navigate(['/offre/soumission', object_id])
        }
       
      }
    )
  }
  getNotification() {
    this.user = GlobalConstants.current_user()
    this.serviceNotification.getNotification('unread').subscribe(
      (response) => {
        this.list_notification_unread = response
        this.unread_notification_count = response.length
      }
    )
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;

    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  isMobileMenu() {
    if (window.outerWidth < 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    var toggleButton = this.toggleButton;
    var html = document.getElementsByTagName('html')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }
  sidebarClose() {
    var html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
  }
  sidebarToggle() {
    if (this.sidebarVisible == false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      var parent = this.listTitles[item];
      if (parent.path === titlee) {
        return parent.title;
      } else if (parent.children) {
        var children_from_url = titlee.split("/")[2];
        for (var current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].title;
          }
        }
      }
    }
    return 'Tableau de bord';
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  ngAfterViewInit() {

    this.user = GlobalConstants.current_user()
    if (this.is_societe || this.is_admin || this.is_societe) {
      if(window.location.protocol === "http:"){
        this.create_socket_notification(`${GlobalConstants.WEBSOCKET_URL}${this.user.id}/`)
      }
      if (window.location.protocol === "https:") {
        this.create_socket_notification(`${GlobalConstants.WEBSOCKET_URLS}${this.user.id}/`)
      }
    }
  }

  getReadNotification() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/users/notifs']);
  }

  create_socket_notification(url: string) {
    let unread_notifications_array = []
    let ws = new ReconnectingWebSocket(url);
    ws.onopen = (e) => {
    }
    ws.onmessage = (event) => {
      let response = JSON.parse(event.data)
      unread_notifications_array.push(response['message'])
      this.list_notification_unread = unread_notifications_array
      this.unread_notification_count = unread_notifications_array.length
    }
    ws.onclose = (e) => {
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }


}


