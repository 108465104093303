import { UsersService } from './../users/users.service';
import { GlobalConstants } from 'app/global-constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';


//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}


export let ROUTES: RouteInfo[] = []

@Component({
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit, OnDestroy {

    username: String = "";
    current_user: any;
    constructor(private router: Router, private userServices: UsersService) { }

    public menuItems: any[];
    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.current_user = GlobalConstants.current_user()
        ROUTES = this.routeInit();
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.username = localStorage.getItem("username");
    }
    ngAfterViewInit() {
    }

    // trace de la deconnnexion
    logOut() {
        this.userServices.logOut().subscribe((response) => {
            localStorage.clear();
            this.router.navigate(['pages/login'])
        }, (error) => { 
            GlobalConstants.errorRequest(error) 
            localStorage.clear();
            this.router.navigate(['pages/login'])
        })
    }

    routeInit() {
        return [
            {
                path: '/dashboard',
                title: 'tableau de bord',
                type: 'link',
                icontype: 'nc-icon nc-bank',
                hidden:true,
            },
            GlobalConstants.has_permission("view_entreprise") && {
                path: '/entreprises',
                title: 'Entreprises',
                type: 'sub',
                collapse: 'entreprises',
                icontype: 'nc-icon nc-planet',
                children: [
                   (GlobalConstants.has_permission("create_entreprise")) && { 
                    path: 'add', title: "Ajouter une entreprise", ab: 'AE',
                    hidden: (GlobalConstants.has_permission("create_entreprise")),
                    },
                    (GlobalConstants.has_permission("view_entreprise")) && { 
                        path: 'list', title: "Liste des entreprises", ab: 'LE',
                        hidden: (GlobalConstants.has_permission("view_entreprise"))
                    },
                ]
            },
            GlobalConstants.has_permission("view_centre_formation") && {
                path: '/centres',
                title: 'Centres de formation',
                type: 'sub',
                collapse: 'centres',
                icontype: 'nc-icon nc-planet',
                children: [
                   (GlobalConstants.has_permission("create_centre_formation")) && { 
                    path: 'ajouter', title: "Ajouter un centre", ab: 'ACF',
                    hidden: (GlobalConstants.has_permission("create_entreprise")),
                    },
                    (GlobalConstants.has_permission("view_centre_formation")) && { 
                        path: 'liste', title: "Liste des centres", ab: 'LCF',
                        hidden: (GlobalConstants.has_permission("view_centre_formation"))
                    },
                ]
            }
            ,
            (this.current_user?.type_utilisateur == 3 )&&{
                path: '/entreprises/entreprises',
                title: 'Mon Entreprise',
                type: 'link',
                icontype: 'nc-icon nc-planet',
                hidden: true,
            },

            GlobalConstants.has_permission("view_own_centre")&&{
                path: '/centres/mon-centre',
                title: 'Mon Centre',
                type: 'link',
                icontype: 'nc-icon nc-planet',
                hidden: true,
            },
            GlobalConstants.has_permission("view_redevance") && {
                path: '/entreprises',
                title: 'Redevances',
                type: 'sub',
                collapse: 'redevances',
                icontype: 'nc-icon nc-money-coins',
                children: [
                   (GlobalConstants.has_permission("create_redevance")) && { 
                    path: 'redevance-add', title: "Ajouter une redevance", ab: 'AR',
                    hidden: (GlobalConstants.has_permission("create_redevance")),
                    },
                    (GlobalConstants.has_permission("view_redevance")) && { 
                        path: 'redevance-list', title: "Liste des redevances", ab: 'LR',
                        hidden: (GlobalConstants.has_permission("view_redevance"))
                    },
                ]
            },
            (GlobalConstants.has_permission("view_agent_list")) &&{
                path: '/agents/liste',
                title: 'Mes Agents',
                type: 'link',
                icontype: 'nc-icon nc-badge',
                hidden: true,
            },
            GlobalConstants.has_permission("view_agent") && {
               path: '/agents',
               title: 'Agents',
               type: 'sub',
               collapse: 'agents',
               icontype: 'nc-icon nc-badge',
               children: [
                  (GlobalConstants.has_permission("create_agent")) && { 
                   path: 'ajouter', title: "Ajouter un agent", ab: 'AG',
                   hidden: (GlobalConstants.has_permission("create_agent")),
                   },

                   (GlobalConstants.has_permission("view_agent")) && { 
                       path: 'liste', title: "Liste des agents", ab: 'LA',
                       hidden: (GlobalConstants.has_permission("view_agent"))
                   },
               ]
           },
            GlobalConstants.has_permission("view_demande_agrement") && {
               path: '/agrements',
               title: 'Agrements',
               type: 'sub',
               collapse: 'agrements',
               icontype: 'nc-icon nc-map-big',
               children: [
                  (GlobalConstants.has_permission("create_demande_agrement")) && { 
                   path: 'add', title: "Ajouter Une Demande", ab: 'AD',
                   hidden: (GlobalConstants.has_permission("create_demande_agrement")),
                   },
                   (GlobalConstants.has_permission("view_demande_agrement")) && { 
                       path: 'list', title: "Liste des Agrements", ab: 'LD',
                       hidden: (GlobalConstants.has_permission("view_demande_agrement"))
                   },
               ]
           },
            (GlobalConstants.has_permission("view_users"))&&{
                path: '/users',
                title: 'Utilisateurs',
                collapse: 'users',
                type: 'sub',
                icontype: 'nc-icon nc-single-02',
                hidden: (GlobalConstants.has_permission("view_users")),
                children: [
                    (GlobalConstants.has_permission("create_users"))&&{ 
                        path: 'users-add', title: 'Ajouter Un Utilisateur', ab: 'UA',
                        hidden:(GlobalConstants.has_permission("create_users"))
                    },
                    { 
                        path: 'users-list', title: 'Liste des utilisateurs', ab: 'LU',
                        hidden: true,
                    },
                    // { 
                    //     path: 'services', title: 'Liste des services', ab: 'LS', 
                    //     hidden: true,
                    // },
                    (GlobalConstants.has_permission("view_typesUsers"))&&{ 
                        path: 'users-type', title: "Type d'Utilisateurs", ab: 'UT',
                        hidden: (GlobalConstants.has_permission("view_typesUsers"))
                    }
                ]
            },
            // {
            //     path: '/statistiques',
            //     title: 'Statistiques',
            //     type: 'link',
            //     icontype: 'nc-icon nc-chart-bar-32',
            //     hidden:true,
            // },
            (GlobalConstants.has_permission("view_document") || GlobalConstants.has_permission("view_centre_formation") || GlobalConstants.has_permission("view_region")) && {
                 path: '/configs',
                 title: 'Configurations',
                 type: 'sub',
                 collapse: 'configs',
                 icontype: 'nc-icon nc-settings',
                 children: [
                    (GlobalConstants.has_permission("view_centre_formation")) && { 
                        path: 'centre_formation', title: "Centre de formation", ab: 'DOC',
                        hidden: (GlobalConstants.has_permission("view_centre_formation"))
                    },
                    (GlobalConstants.has_permission("view_type_entreprise")) && { 
                        path: 'type_entreprise', title: "Type Entreprise", ab: 'TEP',
                        hidden: (GlobalConstants.has_permission("view_type_entreprise"))
                    },
                    (GlobalConstants.has_permission("view_document")) && { 
                        path: 'documents', title: "documents", ab: 'DOC',
                        hidden: (GlobalConstants.has_permission("view_document"))
                    },
                    /* (GlobalConstants.has_permission("view_region")) && { 
                        path: 'region', title: "Region", ab: 'RGN',
                        hidden: (GlobalConstants.has_permission("view_region"))
                    }, */
                 ]
             },

        ];
    }

    ngOnDestroy() {
        this.menuItems = null
    }

}

